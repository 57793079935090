import { Link as RouterLink } from "react-router-dom";
import {
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridTreeNodeWithRender,
} from "@mui/x-data-grid-pro";
import { EditOutlined }  from '@mui/icons-material';
import { IconButton, styled, Typography, Link as MUILink } from "@mui/material";
import GridItem from "./AllListGrid/GridItem";

export const EditOutlinedStyled = styled(EditOutlined)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "#fff" : "rgb(95,99,104)",
}));

export const renderNoHeader = (params: GridColumnHeaderParams) => null;

export const renderHeader = (params: GridColumnHeaderParams) => (
  <Typography variant="overline" noWrap color="text.secondary">
    {params.colDef.headerName}
  </Typography>
);

export const renderCell = (
  params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
) => {
  const value = params.formattedValue;
  return (
    <GridItem title={value} variant="body2">
      {value}
    </GridItem>
  );
};

export const renderLinkCell = (
  params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
) => {
  return (
    <MUILink
      component={RouterLink}
      to={params.row.href}
      underline="none"
      color="inherit"
    >
      <IconButton aria-label="Edit Button" size="large">
        <EditOutlinedStyled />
      </IconButton>
    </MUILink>
  );
};
