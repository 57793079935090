import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Link,
  styled,
} from "@mui/material";
import { FC, ReactNode } from "react";

import { CMLogo, CanvasLogo } from "auditaware-ui";

import useConfig from "../../../hooks/useConfig";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { RoleGuard } from "@reasongcp/react-fire-sub";
import { CMRole, CMRoles } from "auditaware-types";

const LargeAccountCircleIcon = styled(AccountCircleIcon)`
  font-size: 9.5rem;
  fill: #e4d278;
  height: 200px;
`;

const LargeBoatIcon = styled(DirectionsBoatIcon)`
  font-size: 9.5rem;
  fill: #e4d278;
  height: 200px;
`;

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
`;

const Logo: FC<{ logo: ReactNode; alt: string }> = ({ logo, alt }) => {
  if (typeof logo !== "string") {
    return <>{logo}</>;
  }
  return (
    <img src={logo} alt={alt} style={{ width: "auto", height: "200px" }} />
  );
};

const AppCard: FC<{ name: string; url: string; logo: ReactNode }> = ({
  name,
  url,
  logo
}) => {
  return (
    <Link component={RouterLink} to={url}>
      <Card sx={{ width: "300px" }}>
        <CardContent sx={{ display: "flex", justifyContent: "center" }}>
          <Logo logo={logo} alt={name} />
        </CardContent>
        <CardActions sx={{ justifyContent: "center" }}>
          <Button size="small" sx={{ fontSize: "1rem" }}>
            {name}
          </Button>
        </CardActions>
      </Card>
    </Link>
  );
};

const Dashboard: FC = () => {
  const {
    caseClient,
    canvasClient,
    valuationClient,
    valuationWatercraftPath,
    userManagementPath,
  } = useConfig();

  return (
    <StyledBox>
      <RoleGuard roles={[...CMRoles] as string[]}>
        <AppCard name="Case Manager" url={caseClient} logo={CMLogo} />
      </RoleGuard>

      <AppCard name="Digital Canvas" url={canvasClient} logo={CanvasLogo} />

      <RoleGuard roles={[CMRole.ADMIN]}>
        <AppCard
          name="User Management"
          url={userManagementPath}
          logo={<LargeAccountCircleIcon />}
        />
      </RoleGuard>

      {valuationClient && (
        <AppCard
          name="CAVS / Watercraft"
          url={valuationClient + valuationWatercraftPath}
          logo={<LargeBoatIcon />}
        />
      )}
    </StyledBox>
  );
};

export default Dashboard;
