import { FC, useState } from "react";
import { Grid, Box, Fab, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { Drawer, useDrawerControls } from "auditaware-ui";

import StyledPaper from "../../shared/StyledPaper";
import { useMembers } from "../../../hooks/useMembers";
import SearchBox from "../../shared/SearchBox";

import UsersTable from "./UsersTable";
import CreateUser from "./New/CreateUser";

const Users: FC = () => {
  const orgMembers = useMembers();
  const [drawerProps, openDrawer, closeDrawer] = useDrawerControls({
    title: "Create User",
  });

  const orgMembersWithHref = orgMembers.map((member) => {
    return { ...member, href: `/users/${member.id}` };
  });

  const [searchText, setSearchText] = useState("");
  const handleSearch = (search: string) => {
    setSearchText(search);
  };

  const filteredOrgMembers = orgMembersWithHref.filter(member =>
    member?.displayName?.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <Box width="100%">
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs md={12} lg={12}>
          <StyledPaper>
            <Box
              sx={{
                display: "inline-flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "1rem",
              }}
            >
              <Typography variant="h5" color="text.white" component="h1" p={2}>
                Users
              </Typography>
              <SearchBox setSearch={handleSearch} />
            </Box>
            <Box mb={3}>
              <UsersTable rows={filteredOrgMembers} />
            </Box>
          </StyledPaper>
          <Fab
            sx={{ position: "fixed", bottom: "2rem", right: "6rem" }}
            color="primary"
            aria-label="add user"
            onClick={openDrawer}
            title="Add User"
            type="button"
          >
            <AddIcon />
          </Fab>
          <Drawer {...drawerProps}>
            <CreateUser
              afterSubmit={closeDrawer}
              onCancelClick={closeDrawer}
            />
          </Drawer>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Users;
