import { useCallback, useEffect, useMemo, useState } from "react";
import { useSUBContext, useUserDoc } from "@reasongcp/react-fire-sub";
import {
  collection,
  DocumentData,
  onSnapshot,
  QuerySnapshot,
} from "firebase/firestore";

interface Member {
  id: string;
  displayName?: string;
  email?: string;
}

export const useMembers = () => {
  const { firestore } = useSUBContext();
  const [members, setMembers] = useState<Member[]>([]);
  const user = useUserDoc();
  const orgId = user?.currentOrganization;

  const ref = useMemo(() => {
    if (!orgId || !firestore) return null;

    return collection(firestore, "organizations", orgId, "members");
  }, [orgId, firestore]);

  const handleSnapshot = useCallback(
    (snapshot: QuerySnapshot<DocumentData>) => {
      const docs = snapshot.docs.map((doc) => {
        const { displayName, email } = doc.data();

        return { id: doc.id, displayName, email };
      });

      setMembers(docs);
    },
    []
  );

  useEffect(() => {
    if (!ref) return;

    return onSnapshot(ref, handleSnapshot);
  }, [ref, handleSnapshot]);

  return members;
};
