import { FC, useMemo } from "react";
import {
  DataGridPro,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { styled } from "@mui/material";

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  border: 0,
  "& .MuiDataGrid-withBorderColor": {
    borderColor: theme.palette.mode === "dark" ? "#000" : "rgba(0, 0, 0, 0.08)",
  },
}));

interface DataGridProps {
  rows: GridValidRowModel[];
  columns: GridColDef[];
  hiddenColumns?: string[];
  headerHeight?: number;
}

export const DataGrid: FC<DataGridProps> = ({
  rows,
  columns,
  hiddenColumns,
  headerHeight,
}) => {
  const initialState = useMemo(() => {
    if (!hiddenColumns) return {};

    return hiddenColumns.reduce(
      (acc, column) => {
        acc.columns.columnVisibilityModel[column] = false;

        return acc;
      },
      { columns: { columnVisibilityModel: {} as Record<string, boolean> } }
    );
  }, [hiddenColumns]);

  return (
    <StyledDataGrid
      rows={rows}
      columns={columns}
      initialState={initialState}
      columnHeaderHeight={headerHeight}
      density="comfortable"
      disableRowSelectionOnClick={true}
    />
  );
};
