import { useCallback, useEffect, useState } from "react";
import { useUserDoc, useSUBContext } from "@reasongcp/react-fire-sub";
import { doc, DocumentReference, getDoc, updateDoc } from "firebase/firestore";
import useIsAdmin from "./useIsAdmin";

export interface MemberUpdates {
  roles: string[];
  displayName: string;
  email: string;
}

const useUpdateMember = (uid?: string) => {
  const { firestore } = useSUBContext();
  const [memberDoc, setMemberDoc] = useState<DocumentReference | null>(null);
  const [member, setMember] = useState<Record<string, any> | null>(null);
  const userDoc = useUserDoc();
  const isAdmin = useIsAdmin();
  const orgUid = userDoc?.currentOrganization;

  useEffect(() => {
    if (!firestore || !orgUid || !uid) return;
    const orgDoc = doc(firestore, "/organizations", orgUid);
    const mDoc = doc(orgDoc, "/members", uid);
    setMemberDoc(mDoc);
  }, [orgUid, uid, firestore]);

  useEffect(() => {
    if (!firestore || !memberDoc) return;
    getDoc(memberDoc).then((doc) => {
      if (!doc.exists()) return;
      const data = doc.data();
      setMember(data);
    });
  }, [firestore, memberDoc]);

  const updateMember = useCallback(async (details: MemberUpdates) => {
    if (!firestore || !memberDoc) return null;

    if (isAdmin) {
      return updateDoc(memberDoc, {
        ...details,
        roles: details.roles,
      } as Record<string, any>);
    }

    return updateDoc(memberDoc, {
      displayName: details.displayName || "",
      email: details.email || "",
    } as Record<string, any>);
  }, [firestore, memberDoc, isAdmin]);

  return {
    member,
    updateMember,
  };
};

export default useUpdateMember;
