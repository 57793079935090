import { useAuditAwareConfig, Config, Environments } from "auditaware-config";

interface SsoEnvConfig {
  watercraftEnabled: boolean;
}

interface SsoConfig {
  userManagementPath: string;
}

const envConfig: Record<Environments, SsoEnvConfig> = {
  production: {
    watercraftEnabled: false,
  },
  qa: {
    watercraftEnabled: true,
  },
  uat: {
    watercraftEnabled: true,
  },
  development: {
    watercraftEnabled: true,
  },
};

const config: SsoConfig = {
  userManagementPath: "/users",
};

export default function useConfig(): Config & SsoConfig & SsoEnvConfig {
  const auditAwareConfig = useAuditAwareConfig("sso");
  const { releaseStage } = auditAwareConfig;
  return {
    ...envConfig[releaseStage],
    ...config,
    ...auditAwareConfig,
  };
}
