import { SUBContextProvider } from "@reasongcp/react-fire-sub";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import { ApolloProvider } from "auditaware-auth";
import { DrawerPortalProvider, useAuditAwareTheme } from "auditaware-ui";

import AppRoutes from "./components/shared/AppRoutes";
import useConfig from "./hooks/useConfig";

function App() {
  const { firebaseConfig, fireSubApi, authCookieName, useFirebaseEmulator } =
    useConfig();

  const theme = useAuditAwareTheme();

  return (
    <SUBContextProvider
      config={firebaseConfig}
      authCookieName={authCookieName}
      emulate={useFirebaseEmulator}
    >
      <ApolloProvider uri={fireSubApi} bearerPrefix={false}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <DrawerPortalProvider>
              <AppRoutes />
            </DrawerPortalProvider>
          </ThemeProvider>
        </BrowserRouter>
      </ApolloProvider>
    </SUBContextProvider>
  );
}

export default App;
