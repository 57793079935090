import { FC, useCallback } from "react";
import { omitBy } from "lodash";
import { Formik } from "formik";
import { gql, useApolloClient, useMutation } from "@apollo/client";

import { useUserDoc } from "@reasongcp/react-fire-sub";

import UserRoleBoundary from "../../../shared/UserRoleBoundary";
import validationSchema from "../../../../schemas/UserFormSchema";
import { manageUsers } from "../../../../constants/rolePermissions";
import NewUserForm from "./NewUserForm";

export const CREATE_USER = gql`
  mutation CreateUser(
    $email: String!
    $password: String
    $phoneNumber: String
    $displayName: String
    $organizationUid: String!
  ) {
    createUser(
      email: $email
      password: $password
      phoneNumber: $phoneNumber
      displayName: $displayName
      organizationUid: $organizationUid
    ) {
      uid
      email
      displayName
      phoneNumber
    }
  }
`;

const initialValues = {
  role: "user",
  email: "",
  displayName: "",
};

type CreateUserProps = {
  afterSubmit?: () => void;
  onCancelClick?: () => void;
};

type FormValues = typeof initialValues;

const CreateUser: FC<CreateUserProps> = ({
  afterSubmit = () => {},
  onCancelClick = () => {},
}) => {
  const client = useApolloClient();
  const userDoc = useUserDoc();
  const [create] = useMutation(CREATE_USER, { client });

  const organizationUid = userDoc?.currentOrganization || "";

  const handleUserCreation = useCallback(
    (values: FormValues) => {
      const variables = omitBy(
        {
          ...values,
          organizationUid,
        },
        (val: any) => !val
      );

      create({ variables })
        .then(afterSubmit)
        .catch(console.error);
    },
    [create, organizationUid, afterSubmit]
  );

  return (
    <UserRoleBoundary roles={manageUsers} redirectTo="/users">
      <Formik
        initialValues={initialValues}
        onSubmit={handleUserCreation}
        validationSchema={validationSchema}
      >
        <NewUserForm onCancelClick={onCancelClick} />
      </Formik>
    </UserRoleBoundary>
  );
};

export default CreateUser;
