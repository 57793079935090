import roleMappings, { UserRole } from "./roleMappings";

// NOTE: An empty array implies admin only access.

/* *** BEGIN ADMIN ONLY*** */

// Users
export const manageUsers: UserRole[] = [];

// Organization
export const manageOrganization: UserRole[] = [];

/* ***END ADMIN ONLY*** */

/* *** BEGIN USER PERMISSIONS *** */

// This sections is currently unused. It is here for future use.

// Forms
export const ssoViewer: UserRole[] = [roleMappings.user];

export const editForms: UserRole[] = [roleMappings.user];

export const viewForms: UserRole[] = [roleMappings.user];

export const submitForms: UserRole[] = [roleMappings.user];

/* *** END USER PERMISSIONS *** */
