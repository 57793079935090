import { FC, useCallback, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Grid,
  Button,
  Snackbar,
  Alert,
  Typography,
  Paper,
  Link,
  Fab,
  AlertColor,
} from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { useSUBContext } from "@reasongcp/react-fire-sub";
import { Formik, Form as FormikForm, FormikHelpers } from "formik";
import backgroundImg from "../../../images/triangle-background.svg";
import logoBlue from "../../../images/AA-blue.png";
import logoWhite from "../../../images/AA-white.png";

import validationSchema from "../../../schemas/YupForgotFormSchema";
import { TextField } from "auditaware-ui";
import { sendPasswordResetEmail } from "firebase/auth";
import { useTernaryDarkMode } from "usehooks-ts";
import { FirebaseError } from "firebase/app";

interface ForgotFormValues {
  email: string;
}

interface SnackbarOpts {
  message?: string;
  severity?: AlertColor;
}

const USER_NOT_FOUND = "auth/user-not-found";

const Forgot: FC = () => {
  const [snackbarOpts, setSnackbarOpts] = useState<SnackbarOpts | null>(null);
  const { auth } = useSUBContext()
  const { isDarkMode } = useTernaryDarkMode();

  const themeLogo = isDarkMode ? logoWhite : logoBlue;

  const handleSubmit = useCallback(
    async (
      values: ForgotFormValues,
      { setSubmitting }: FormikHelpers<ForgotFormValues>
    ) => {
      const { email } = values;
      console.log(auth, email, "woot");

      if (!auth) return;

      try {
        await sendPasswordResetEmail(auth, email);
        setSnackbarOpts({message: "Password reset email sent!", severity: "success"});
      } catch (error: unknown) {
        if (error instanceof FirebaseError && error.code === USER_NOT_FOUND) {
          setSnackbarOpts({message: "User not found.", severity: "error"});
        } else {
          setSnackbarOpts({message: "Error sending password reset email.", severity: "error"});
        }
        console.error(error);
      }
      setSubmitting(false);
    },
    [auth]
  );

  const handleCloseSnackbar = useCallback(() => {
    setSnackbarOpts(null);
  }, []);

  const initialValues: ForgotFormValues = {
    email: "",
  };

  return (
    <Grid
      component="main"
      container
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        maxWidth: "100vw",
        backgroundImage: `url(${backgroundImg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "103% auto",
        backgroundPosition: "bottom -85px left -10px",
      }}
    >
      <Box sx={{ m: 1, display: "flex", flexDirection: "row" }}>
        <img
          src={`${themeLogo}`}
          alt="Audit Aware"
          width="auto"
          height="70px"
        />
      </Box>
      <Grid container>
        <Box
          sx={{
            mx: "auto",
            display: "flex",
            flexDirection: "column",
            width: "395px",
          }}
        >
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ isSubmitting }) => (
              <FormikForm>
                <Paper
                  elevation={3}
                  sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
                >
                  <Box>
                    <Typography
                      component="h1"
                      variant="h4"
                      fontWeight="light"
                      align="center"
                      gutterBottom
                      mb={3}
                      mt={2}
                    >
                      Audit Aware
                      <br />
                      SSO Login
                    </Typography>
                    <TextField
                      required
                      fullWidth
                      name="email"
                      id="email"
                      label="Email"
                      margin="normal"
                    />
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Link
                        component={RouterLink}
                        to="/login"
                        color="inherit"
                        variant="button"
                      >
                        Return to login
                      </Link>
                    </Box>

                    <Button
                      fullWidth
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                      sx={{ my: "2rem" }}
                    >
                      Reset Password
                    </Button>
                  </Box>
                </Paper>
              </FormikForm>
            )}
          </Formik>
          <Snackbar
            open={!!snackbarOpts?.message}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            message={snackbarOpts?.message}
          >
            <Alert
              onClose={handleCloseSnackbar}
              sx={{ width: "100%" }}
              severity={snackbarOpts?.severity}
            >
              {snackbarOpts?.message}
            </Alert>
          </Snackbar>
        </Box>
      </Grid>
      <Box sx={{ position: "absolute", bottom: 10, left: 10 }}>
        <Fab
          variant="extended"
          color="info"
          aria-label="Contact Us"
          size="small"
          href="https://reason.consulting/contact/"
          target="_blank"
        >
          <HelpOutlineOutlinedIcon sx={{ mr: 1 }} fontSize="small" /> Contact Us
        </Fab>
      </Box>
    </Grid>
  );
};

export default Forgot;
