/* eslint-disable max-len */
import { FC, useCallback, useState } from "react";
import { Navigate, Link as RouterLink } from "react-router-dom";
import {
  Box,
  Grid,
  Button,
  Snackbar,
  Alert,
  Typography,
  Paper,
  Link,
  Fab,
} from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { useAuth, useSignin } from "@reasongcp/react-fire-sub";
import { Formik, Form as FormikForm, FormikHelpers } from "formik";
import backgroundImg from "../../../images/triangle-background.svg";
import logoBlue from "../../../images/AA-blue.png";
import logoWhite from "../../../images/AA-white.png";

import validationSchema from "../../../schemas/YupLoginFormSchema";
import { TextField } from "auditaware-ui";
import { AuthError, AuthErrorCodes } from "firebase/auth";
import { useTernaryDarkMode } from "usehooks-ts";

interface LoginFormValues {
  email: string;
  password: string;
}

// can't find in the AuthErrorCodes list /shrug
const USER_NOT_FOUND = "auth/user-not-found";

const Login: FC = () => {
  const [snackbarMsg, setSnackbarMsg] = useState<string | null>(null);
  const user = useAuth();
  const signin = useSignin();
  const { isDarkMode } = useTernaryDarkMode();

  const themeLogo = isDarkMode ? logoWhite : logoBlue;

  const handleSubmit = useCallback(
    async (
      values: LoginFormValues,
      { setSubmitting }: FormikHelpers<LoginFormValues>
    ) => {
      const { email, password } = values;

      try {
        await signin(email, password);
      } catch (err: any) {
        const error = err as AuthError;
        switch (error.code) {
          /* prettier-ignore */
          case AuthErrorCodes.INVALID_EMAIL:
            setSnackbarMsg("Invalid email address. Please try again.");
            break;
          case AuthErrorCodes.INVALID_PASSWORD:
            setSnackbarMsg(
              "Invalid password. Please try again or reset your password."
            );
            break;
          case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
            setSnackbarMsg(
              "Too many requests. Please wait at least 60 seconds and try again."
            );
            break;
          case USER_NOT_FOUND:
            setSnackbarMsg(
              "This email address is not registered. Please contant support."
            );
            break;
          default:
            setSnackbarMsg(`Server Error ${error.code}`);
        }

        setSubmitting(false);
      }
    },
    [signin]
  );

  const handleCloseSnackbar = useCallback(() => {
    setSnackbarMsg(null);
  }, []);

  if (user?.emailVerified) {
    return <Navigate to="/" />;
  }

  const initialValues: LoginFormValues = {
    email: "",
    password: "",
  };

  return (
    <Grid
      component="main"
      container
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        maxWidth: "100vw",
        backgroundImage: `url(${backgroundImg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "103% auto",
        backgroundPosition: "bottom -85px left -10px",
      }}
    >
      <Box sx={{ m: 1, display: "flex", flexDirection: "row" }}>
        <img
          src={`${themeLogo}`}
          alt="Audit Aware"
          width="auto"
          height="70px"
        />
      </Box>
      <Grid container>
        <Box
          sx={{
            mx: "auto",
            display: "flex",
            flexDirection: "column",
            width: "395px",
          }}
        >
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ isSubmitting }) => (
              <FormikForm>
                <Paper
                  elevation={3}
                  sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
                >
                  <Box>
                    <Typography
                      component="h1"
                      variant="h4"
                      fontWeight="light"
                      align="center"
                      gutterBottom
                      mb={3}
                      mt={2}
                    >
                      Audit Aware
                      <br />
                      SSO Login
                    </Typography>
                    <TextField
                      required
                      fullWidth
                      name="email"
                      id="email"
                      label="Email"
                      margin="normal"
                    />
                    <TextField
                      required
                      fullWidth
                      id="passsword"
                      name="password"
                      label="Password"
                      type="password"
                      autoComplete="current-password"
                      margin="normal"
                    />
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Link
                        component={RouterLink}
                        to="/forgot"
                        color="inherit"
                        variant="button"
                      >
                        Forgot Password
                      </Link>
                    </Box>

                    <Button
                      fullWidth
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                      sx={{ my: "2rem" }}
                    >
                      Log In
                    </Button>
                  </Box>
                </Paper>
              </FormikForm>
            )}
          </Formik>
          <Snackbar
            open={!!snackbarMsg}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            message={snackbarMsg}
          >
            <Alert
              onClose={handleCloseSnackbar}
              sx={{ width: "100%" }}
              severity="error"
            >
              {snackbarMsg}
            </Alert>
          </Snackbar>
        </Box>
      </Grid>
      <Box sx={{ position: "absolute", bottom: 10, left: 10 }}>
        <Fab
          variant="extended"
          color="info"
          aria-label="Contact Us"
          size="small"
          href="https://reason.consulting/contact/"
          target="_blank"
        >
          <HelpOutlineOutlinedIcon sx={{ mr: 1 }} fontSize="small" /> Contact Us
        </Fab>
      </Box>
    </Grid>
  );
};

export default Login;
