import { FC, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, IconButton } from "@mui/material";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid-pro";
import EditIcon from "@mui/icons-material/Edit";

import { Drawer, useDrawerControls } from "auditaware-ui";

import { DataGrid } from "../../shared/DataGrid";
import {
  renderCell,
  renderHeader,
} from "../../shared/DataGridHelpers";

import EditUserForm from "./Edit/EditUserForm";

interface UsersTableProps {
  rows: GridValidRowModel[];
}

const UsersTable: FC<UsersTableProps> = ({ rows }) => {
  const [selectedUser, setSelectedUser] = useState<GridValidRowModel | null>(null);
  const navigate = useNavigate();

  const [
    drawerProps,
    openDrawer,
    closeDrawer,
  ] = useDrawerControls({ title: "Edit User" });

  const handleAfterSubmit = useCallback(() => {
    closeDrawer();
    navigate("/users");
  }, [closeDrawer, navigate]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "displayName",
        headerName: "Name",
        renderHeader,
        renderCell,
        flex: 1,
      },
      {
        field: "email",
        headerName: "Email",
        renderHeader,
        renderCell,
        flex: 1,
      },
      {
        field: "edit",
        headerName: "Edit",
        renderHeader,
        sortable: false,
        disableColumnMenu: true,
        width: 80,
        renderCell: (user) => {
          return (
            <IconButton
              aria-label="Edit User"
              title="Edit User"
              onClick={() => {
                setSelectedUser(user.row);
                openDrawer();
              }}
            >
              <EditIcon color="primary" fontSize="small" />
            </IconButton>
          );
        },
      },
    ],
    [openDrawer]
  );

  return (
    <Box mb={3}>
      <DataGrid rows={rows} columns={columns} headerHeight={30} />
      <Drawer {...drawerProps}>
        <EditUserForm
          uid={selectedUser?.id}
          onCancelClick={closeDrawer}
          afterSubmit={handleAfterSubmit}
        />
      </Drawer>
    </Box>
  );
};

export default UsersTable;
