import { FC } from "react";
import { Navigate, useLocation } from "react-router-dom";

const LoginRedirect: FC = () => {
  const location = useLocation();

  return <Navigate to="/login" state={{ from: location }} replace />;
};

export default LoginRedirect;
