import { Route, Routes } from "react-router-dom";
import { gql, useApolloClient } from "@apollo/client";
import { SSOAuthBoundary } from "@reasongcp/react-fire-sub";

import { Layout } from "auditaware-ui";

import LoginRedirect from "./LoginRedirect";
import LoadingPlaceholder from "./LoadingPlaceholder";
import Login from "../../pages/Login";
import Forgot from "../../pages/Forgot";
import Users from "../../pages/Users";
import RestrictedRoutes from "./RestrictedRoutes";
import { manageUsers } from "../../../constants/rolePermissions";
import Dashboard from "../../pages/Dashboard";
import useConfig from "../../../hooks/useConfig";

const AppRoutes = () => {
  const client = useApolloClient();
  const { ssoClient } = useConfig();

  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot" element={<Forgot />} />

        <Route
          path="/*"
          element={
            <SSOAuthBoundary
              gql={gql}
              client={client}
              InvalidPlaceHolder={LoginRedirect}
              LoadingPlaceHolder={LoadingPlaceholder}
            >
              <Layout appName="SSO" ssoClient={ssoClient}>
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                </Routes>
                <RestrictedRoutes roles={manageUsers} redirectTo="/">
                  <Route path="users" element={<Users />}>
                  </Route>
                </RestrictedRoutes>
              </Layout>
            </SSOAuthBoundary>
          }
        />
      </Routes>
    </>
  );
};

export default AppRoutes;
