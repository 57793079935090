import { FC, PropsWithChildren } from "react";
import { Routes } from "react-router-dom";
import { UserRole } from "../../../constants/roleMappings";
import UserRoleBoundary from "../UserRoleBoundary";

export interface RestrictedRouteProps {
  roles?: UserRole[];
  allowAll?: boolean;
  redirectTo?: string;
}

const RestrictedRoutes: FC<PropsWithChildren<RestrictedRouteProps>> = ({
  roles,
  allowAll,
  redirectTo,
  children,
}) => {
  return (
    <UserRoleBoundary roles={roles} allowAll={allowAll} redirectTo={redirectTo}>
      <Routes>{children}</Routes>
    </UserRoleBoundary>
  );
};

export default RestrictedRoutes;
